import { experimental_extendTheme as extendTheme } from '@mui/material/styles'

// Types
import type { Direction } from '@mui/material/styles'

// Fonts
import CalibriFont from '@/styles/fonts/calibri'

const ExtendThemeConfig = (dir: Direction) =>
	extendTheme({
		direction: dir,
		colorSchemes: {
			light: {
				palette: {
					mode: 'light',
					primary: { light: '#eeecff', main: '#5b41ff', dark: '#0f054c', contrastText: '#fff' },
					secondary: { light: '#fdc64b', main: '#ffa800', dark: '#ff961c', contrastText: '#0f054c' }
				}
			},
			dark: {
				palette: {
					mode: 'dark',
					primary: { light: '#fff', main: '#fff', dark: '#fff', contrastText: '#0f054c' },
					secondary: { light: '#fdc64b', main: '#ffa800', dark: '#ff961c', contrastText: '#0f054c' }
				}
			}
		},
		shape: { borderRadius: 8 },
		typography: {
			fontFamily: CalibriFont.style.fontFamily
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: { scrollBehavior: 'smooth' },
					a: { textDecoration: 'none' }
				}
			}
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				smmd: 720,
				md: 900,
				lg: 1200,
				xl: 1536
			}
		}
	})

export default ExtendThemeConfig
