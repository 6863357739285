import localFont from 'next/font/local'

const CalibriFont = localFont({
	src: [
		{ path: './light.woff2', weight: '300' },
		{ path: './regular.woff2', weight: '400' },
		{ path: './bold.woff2', weight: '700' }
	],
	display: 'swap',
	style: 'normal'
})

export default CalibriFont
